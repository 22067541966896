* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Ubuntu', 'M PLUS Rounded 1c', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(10px + 1vmin);
  background-image: url(./images/bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="submit"],
input[type="file"],
select {
  font-family: 'Ubuntu', 'M PLUS Rounded 1c', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: inherit;
}
