.App {
  text-align: center;
}

.App-container {
  height: calc(100vh - 4.5em);
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: calc(600px + 4em);
  margin-left: auto;
  margin-right: auto;
  padding: 0 2em 2em;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 5em;
}

.App-header h1 {
  font-size: 2rem;
  margin-bottom: 2em;
  text-shadow: 0 0 .5em rgba(255, 255, 255, .8);
}

.App-main {
  width: 100%;
}

.App-main form {
  width: 100%;
}

.Form-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1em 0;
}

.Form-field {
  width: 100%;
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Form-field label {
  margin-bottom: 1em;
  text-shadow: 0 0 .5em rgba(255, 255, 255, .8);
}

.Form-field input[type="file"] {
  font-size: inherit;
}

.Form-field-half {
  width: 50%;
}

.Form-field-select {
  border-radius: 4px;
  padding: .5em;
  width: 80%;
}

.Form-field-file {
  width: 100%;
  background-color: #eee;
  border-radius: 4px;
  padding: 5em 2em;
}

.Form-field-submit {
  display: block;
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: .6em;
  background-color: #27b;
  color: #fff;
}

.Footer {
  background-color: #333;
  color: #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 4.5em;
  line-height: 1.6;
}

.Footer-link {
  color: #eee;
  text-decoration: none;
}

.Footer-unsplash-credit a {
  color: #eee;
  text-decoration: none;
}
